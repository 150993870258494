import { useState } from "react";
const SearchBar = ({onSearchChange}) => {

    const [search, setSearch] = useState('');

    const manageSearch = ({target}) => {
        const s = target.value;
        setSearch(s);
        if(s.trim().length > 3) {
            onSearchChange(target.value);
        }
    }

    const searchMovies = (evt) => {
        evt.preventDefault(); //Devo farlo perché sono dentro a un form e l'evento click risalirebbe a far eseguire il form
        console.log('Search for:', search)
        onSearchChange(search);
    }

    return (
        <form className="d-flex">
            <input className="form-control me-2" type="search" 
            name = "search" value={search} onChange={(e)=>manageSearch(e)} placeholder="Search" aria-label="Search" />
            <button onClick={(e)=>{e.preventDefault();setSearch('');}} className="btn btn-outline-light" type="submit">Reset</button>
            <button onClick={(e)=>{searchMovies(e)}} className="btn btn-outline-primary" type="submit">Search</button>
        </form>
    );
}

export default SearchBar;