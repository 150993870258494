import MovieItem from "./MovieItem";

const MovieList = ({ movies, totalCount }) => {
    return (
        <div className="row d-flex justify-content-center">
            {movies.map(movie => <MovieItem key={movie.imdbID} {...movie}/>)}
            <h3>Total founds: {totalCount}</h3>
        </div>
    );
}

export default MovieList;