import ModalMovieDetails
    from "./ModalMovieDetails";
const MovieItem = ({ Poster, Title, imdbID, Year, Type }) => {
    return (
        <>
            <div className="card m-2 col-md-3 col-lg-6" style={{ width: '18rem' }}>
                <img src={Poster} className="card-img-top" alt={Title} />
                <div className="card-body">
                    <h5 className="card-title">{Title}</h5>
                </div>
                <div className="card-footer">
                    <ModalMovieDetails imdbID={imdbID} Title={Title} />
                </div>
            </div>

        </>
    );
}

export default MovieItem;