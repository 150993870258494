import SearchBar from "./SearchBar";
import Menu from "./Menu";
const NavBar = ({onSearchChange}) => {
    return (
        <header>
            <nav className="navbar navbar-expand-lg navbar-dark collapse.navbar-collapse" >
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">Navbar</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <Menu />
                    <SearchBar onSearchChange={onSearchChange}/>
                </div>
            </nav>
        </header>
    );
}

export default NavBar;