import './App.css';
import ENV from './utils/environment';
import { useState, useEffect } from 'react';
import MovieList from './components/MovieList';
import NavBar from './components/NavBar';

async function fetchMovies(search = 'The godfather') {
  const response = await fetch(ENV.API_URL + '?apiKey=' + ENV.API_KEY + '&s=' + search)
    .then(res => res.json());
  const { Error: error, Search: movies, totalResults: totalCount } = response;
  console.log({error, movies, totalCount });
  return { error, movies, totalCount };
}

function App() {
  console.log('rendering App', process.env.REACT_APP_APPLICATION_NAME);

  const callApi = async (search = 'The godfather') => {
    const data = await fetchMovies(search);
    setMoviesResult({ error: data.error, movies: data.movies? data.movies : [], totalCount: data.totalCount ? data.totalCount : 0 });
  }

  const [moviesResult, setMoviesResult] = useState({ movies: [], totalCount: 0 });
  
  useEffect(() => {

    callApi();
    return () => {
      //cleanup
    }
  }, []);
  return (
    <>
      <NavBar onSearchChange= {callApi}/>
      <div className="App container-fluid">
        <h1 style={{ color: "white" }}>MY FAVORITE MOVIES ({moviesResult.totalCount})</h1>
        { !moviesResult.error ? <MovieList key="imdb-movies-list" {...moviesResult} />
         : <h3>{moviesResult.error}</h3>}
      </div>
    </>
  );
}

export default App;
