import { useState } from "react";

import ENV from '../utils/environment';

const ModalMovieDetails = ({imdbID, Title}) => {
    const [description, setDescription] = useState('');
    console.log('Rendering ModalMovieDetails', imdbID, Title, description);
    const fetchMovieByID = async () => {
        return await fetch(ENV.API_URL + `/?i=${imdbID}&plot=full&apiKey=${ENV.API_KEY}`);
        
    }

    const openDetails = async (e) => {
        console.log('openDetail');
        const response = await fetchMovieByID();
        response.json().then(movieById => {
        console.log(movieById.Plot);
           setDescription(movieById.Plot);
        })
    }
    return (
        <>
            <button type="button" className="btn btn-primary" data-bs-toggle="modal"
                data-bs-target={`#modalMovieDetails-${imdbID}`} onClick={(e) => openDetails(e)}>
                VIEW DETAILS
            </button>
            <div className="modal fade" id={`modalMovieDetails-${imdbID}`} tabIndex={-1} aria-labelledby={`modalMovieDetails-${imdbID}`} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id={`modalMovieDetails-${imdbID}`}>{Title}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            {description}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default ModalMovieDetails;